header.top {
	background-color: #fafafa;
	-webkit-box-shadow: 0 8px 6px -8px #777;
	-moz-box-shadow: 0 8px 6px -8px #777;
	box-shadow: 0 8px 6px -8px #777;
	z-index:9999;
	position:relative;
	.navbar-nav {
		justify-content:center;
	}
	.topmenu {
		margin:0;
		li {
			display: inline-block;
			padding: 10px 22px;
			font-size: 1.9rem;
			margin: 0;
			a {
				color: #575348;
				display: block;
				text-decoration: none;
				font-family: $montserrat;
				font-weight:300;
				border-bottom:2px solid #FAFAFA;
				@media(max-width:992px) {
					border-bottom:0;
				}
				&:hover {
					border-bottom:2px solid #0B284C;
					color:#0B284C;
					@media(max-width:992px) {
						border-bottom:0;
					}
				}
				&.burger {
					display:none;
				}
			}
			&.search {
				width: 48px;
				height: 48px;
				text-align: center;
				padding: 0;
				margin-right: 30px;
				a:hover {
					color: #000;
					border-bottom: none;
				}
			}
		}
	}
	.search-bar {
		display: none;
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fafafa;
		text-align: center;
		form {
			margin-top: 0;
			width: 100%;
			height: 100%;
			.search-input {
				width: 60vw;
				font-size: 1.2rem;
				color: $charcoal;
				font-weight: 300;
				border-radius: 0;
				padding-bottom: 5px;
			}
			&:-ms-input-placeholder {
				opacity: 1;
				color: #c8caca;
			}
			button {
				font-size: 1.5rem;
				background: none;
				border: 0;
				&:hover {
					color:$darkblue;
				}
			}
			.search-input,
			.search-input:focus {
				border: none;
				border-bottom: 1px solid #000;
				outline: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				background-color: transparent;
			}
		}
	}
}

.nav-sidenav {
	li {
		.nav-item {
			font-size:1rem;
		}
	}
}
.col-md-10 {
	margin: 0 auto;
	align-items: center;
}
.container {
	align-items: center;
	min-height: 72px;
}

// Use to note menu is showing on mobile
.burger-nav {
	position: absolute;
	width: 100%;
	top: 110px;  // starting value, set in js
	// left: -15px;
	right: 0;
	padding: 0;

	// This is the mobile drop down
	li:not(.nav-mobile) {
		position: relative;
		font-size: 16px;
		// top: 354px;
		// left: -60vw;
		background-color: white;

		&::before {
			content: " + ";
		}

		a {
			display: inline-block !important;  // this needs to be replaced in the js code when i figure out how to target the a
		}
	}
	.search.nav-mobile {
		position: absolute;
		right: 79px;
		top: -90px;
	}
	.nav-mobile{
		position: absolute;
		right: 38px;
		top: -89px;
		@media (max-width: 768px) {
			right: 23px;
			top: -100px;
		}
	}
}

@media (max-width: 768px) {
	header.top {
		padding-top: 15px;
		padding-bottom: 9px;
		.search-bar {
			form {
				.search-input {
					width: 90%;
					font-size: 1.3rem;
				}
			}
		}
		.logo {
			img {
				max-height:50px;
			}
		}
	}
}
@media (max-width: 350px) {
	header.top .logo img {
		height: 45px;
		max-height: 45px;
		object-fit: cover;
	}
	header.top .topmenu li.btn-menu a {
		font-size: 13px;
	}
}



@media (min-width: 576px) and (max-width: 991.98px) {
	header.top .topmenu li.btn-menu {
		padding: 0 39px 0 0;
	}
}

@media (min-width: 992px) {
	header.top {
		.topmenu {
			li {
				font-size: 1rem;
				font-weight: bold;
				padding: 10px 20px;
				&.search {
					height: 44px;
					line-height: 42px;
					margin-right: 0;
				}
				.btn-menu {
					a {
						font-size: 20px;
						height: 44px;
						line-height: 42px;
					}
				}
			}
		}
	}
}
@media (max-width: 360px) {
	header.top .search-bar form .search-input {
		font-size: 1rem;
	}
}
@media (max-width: 992px) {
	header.top {
		// Hide top menu except for search and hamburger
		.topmenu {
			li:not(.nav-mobile) {
				display:none;
			}
			&.search {
				display: inline-block;
				margin-right: 1rem;
				line-height: 2rem;
			}
			.search {
				&.nav-mobile {
					//background-color: red;
					margin-right: -30px;
				}
				.fa-lg {
					font-size:1em;
				}
			}
			.nav-mobile {
				font-size: 1rem;
				font-weight: 700;
				margin-right: -10px;
				a {
					text-decoration: none !important;

					&.burger {
						display:inline-block;
						
					}
				}
				
			}
		}

		.burger {
			// color: black;
			display: inline-block;
			// margin-top: -0.5rem;
		}
	}
}

/* FontAwesome */

.fas {
	font-family: FontAwesome;
	font-style: normal;
}
.fa-search {
	bottom: 0;
	position: absolute;
	right: 10%;
}
.fa-search,
button {
	cursor: pointer;
}
.search-bar .fa-search,
.topmenu .search i {
	position: relative;
}
@media (max-width: 768px) {	
	.col-spacer {
		display: none;
	}
}
