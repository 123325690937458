.grid-people-extended{
    padding: 0 6%;
    font-weight: 300;
    color: #707070;

    .person-about{
        // MOBILE ABOUT SECTION
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 5%;
        padding-bottom: 10%;
        .double-images {
            img {
                width:100%;
                margin-bottom:60px;
                &:nth-child(2n) {
                    width:100%;
                }
            }
        }
        img {
            height:auto;
            margin-bottom:30px;
        }
        h3{
            font-size: 18px;
            padding-bottom: 30px;
            line-height: 30px;
            font-weight: 300;
            margin:0;
        }
        // DESKTOP ABOUT SECTION
        @media(min-width: 1000px){
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 5%;

            h3{
                font-size: 30px;
                padding-bottom: 30px;
                line-height: 45px;
            }
        }
        article.single-people-text {
            display:block;
            ul {
                list-style-type:disc;
                margin-left:30px;
            }
            h4 {
                margin-top:30px;
            }
        }
    }

    .projects-grid{
        // MOBILE KEY PROJECTS SECTION
        margin-bottom:120px;
        .key-projects {
            margin-bottom:50px;
        }
        .project-images{
            display: grid;
            gap:4vw;
            .group-img {
                padding-bottom:0;
                position:relative;
                margin:0;
                box-sizing: border-box;
                height:fit-content;
                img {
                    width:100%;
                    object-fit:contain;
                    max-height:500px; 
                    height:max-content; 
                }
                .overlay-text {
                    display:none;
                    ul {
                        margin-bottom:10px;
                        padding:0 30px;
                        li{
                            font-size:0.9rem;
                            letter-spacing: 0.51px;
                            color: #8AD2D0;
                            text-transform: uppercase;
                            font-weight:700;
                            display:inline-block;
                            margin-right:5px;
                            &:after {
                                content:"+";
                                margin-left:5px;
                            }
                            &:last-child:after {
                                content: "";
                            }
                        }
                    }
                    h3{
                        font-size:calc(15px + 1vw);
                        color: #FFFFFF;
                        padding:0 30px;
                        font-weight:500;
                        margin-bottom:30px;
                    }
                }
                &:hover {
                    .overlay-text {
                        height:100%;
                        width:100%;
                        display:flex;
                        flex-direction:column;
                        justify-content:flex-end;
                        -webkit-justify-content: flex-end;
                        position:absolute;
                        bottom:0;
                        left:0;
                        background: linear-gradient( 180deg, rgb(0, 0, 0, 0), rgb(0, 0, 0, .9));
                    }
                }
            }
            @media (min-width:767px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .loadmore{
            text-align: center;
            margin:50px auto 0 auto;
            display:block;
            cursor:pointer;
            &:hover {
                color:#fff;
            }
        }
    }
}
