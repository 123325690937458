/* GLOBAL */

$montserrat: 'Montserrat', sans-serif;

/*Colors */
$charcoal: #575348;
$lightblue: #B7CBDD;
$darkblue: #0B284C;
$slate: #E1E9F0;
$white: #fff;
$gray: #eee;

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: $montserrat;
}

body {
  font-weight: 400;
}
   
html {
  box-sizing: border-box;
  font-size: 100%;
}

.container-fluid {
  padding:0 !important;
}

article{
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

p {
  font-family:$montserrat;
  color:$charcoal;
  font-size:1.1rem;
  line-height:2rem;
  font-weight:400;
}

.tcenter {
  text-align:center;
}

h1,h2,h3,h4,h5,h6 {
  font-family:$montserrat;
  font-weight:300;
  color:$darkblue;
  a {
    color:$darkblue;
    text-decoration:none;
    &:hover {
      text-decoration:none;
      color:#333;
    }
  }
}

.container {
  ul,ol {
    margin-left:30px;
    li {
      font-family:$montserrat;
      color:$charcoal;
      font-size:1.1rem;
      line-height:2rem;
      font-weight:400;
    }
  }
  ul {
    list-style-type:disc;
  }
  ol {
    list-style-type:decimal;
  }
  a {
    color:$darkblue;
    text-decoration:underline;
    &:hover {
      color:#000;
      text-decoration:none;
    }
  }
}

h1 {
  font-size: calc(18px + 3vw);
  margin-bottom:30px;
}

article.post h1 {
  font-size: calc(10px + 2vw);
  @media(max-width:767px) {
    font-size:calc(16px + 3vw);
  }
}

h2 {
  font-size: calc(12px + 2vw);
  margin-bottom:20px;
  @media(max-width:767px) {
    font-size:calc(14px + 3vw);
  }
}
  
h3 {
  font-size: calc(14px + 1vw);
  margin-bottom:20px;
  line-height: 2.6rem;
  @media(max-width:767px) {
    font-size: calc(12px + 2.5vw);
    line-height:2.2rem;
  }
}

h4 {
  font-size:1.4rem;
  font-weight:300;
  margin-bottom:20px;
  line-height:2rem;
}

h5 {
  font-size:1.2rem;
  font-weight:700;
  color:$charcoal;
  margin-bottom:10px;
}

a.button {
  border:1px solid $charcoal;
  color:$charcoal;
  font-size:0.9rem;
  padding:10px 35px;
  display:inline-block;
  text-decoration:none;
  transition:0.3s ease all;
  width:fit-content;
  font-weight:300;
  height:fit-content;
  &:hover {
    background:$charcoal;
    color:$white;
  }
  &.center {
    margin:0 auto;
  }
}

/* PAGE HEADERS */
.page-header {
  display:grid;
  grid-template-columns:50% 1fr;
  position:relative;
  min-height:30vh;
  @media (max-width:767px) {
      grid-template-columns:1fr;
      min-height:auto;
      margin-bottom:40px;
  }
  &:after {
    content:"";
    height:1px;
    width:50vw;
    background:#707070;
    top:50%;
    right:0;
    position:absolute;
    @media (max-width:767px) {
        display:none;
    }
  }
  .page-title {
      display:grid;
      padding:5em 15%;
      justify-content:center;
      align-content:center;
      @media (max-width:767px) {
          justify-content:left;
          padding:3em 10%;
      }
      /*Article Meta */
      ul {
          display:flex;
          justify-content:left;
          font-family:$montserrat;
          font-size:0.9rem;
          li {
              color:$charcoal;
              font-weight:700;
              &:first-child:after {
                  content:"•";
                  margin:0 15px;
              }
          } 
      }
  } 

  .page-image {
    z-index:999;
    max-height:60vh;
    @media (max-width:767px) {
        grid-area: 2 / 1 / 2 / 1;
    }
    img {
      height:100%;
      object-fit:cover;
    }
  }
}


/* CONTACT BLOCK */

.contact-info{
  background-color: #E1E9F0;
  padding:15% 10% 5% 10%;
  h2 {
    margin-bottom:60px;
  }
  .contact-sect{
      padding-bottom: 30px;
      a {
       color:$darkblue;
      }
  }
}

@media (min-width:767px) {
  .contact-info {
    background-color: #E1E9F0;
    padding:15% 15%;
    text-align: left;  
    .contact-sect{
      display: block;
      padding-bottom: 60px;
      a[href^="tel:"] {
          color:#575348;
      }
    }
  }
}

.social{
  // MOBILE SOCIAL
  padding-bottom: 4em;
  background-color: #E1E9F0;

 .child{
  padding-top: 2%;
  text-align: center;
  }

  .child-text{
      text-align: center;
      padding-top: 8%;
  }
  
  .social-icon{   
      width: 50px;
      height: 50px;
      background-color: #B7CBDD;
      padding: 2%;
      border-radius: 50%;
      fill: white;
      margin:0 5px;
      display:inline-block;
      transition:0.2s ease all;
      &:hover {
          background: #0C284B;
      }
  }
  
  // DESKTOP SOCIAL

 @media (min-width:767px) {
  font-size: 20px;
  background-color: white;
  text-align:right;
  padding-right:10%;
  .child{
      display: inline-block;
      padding-top: 2%;
  }

  .child-text{
      padding-right:20px;
  }
  .social-icon{
      width: 65px;
      height: 65px;
      background-color: #B7CBDD;
      padding:10%;
      border-radius: 50%;
      fill: white;
      margin:0 7px;
  }
 }   
} 

/* LIGHTBOX CODE */
#slb_viewer_wrap .slb_theme_slb_default .slb_data_title, #slb_viewer_wrap .slb_theme_slb_default .slb_group_status {
  font-family:$montserrat !important;
  font-weight:300;
}

#slb_viewer_wrap .slb_theme_slb_default .slb_content .slb_prev .slb_template_tag, #slb_viewer_wrap .slb_theme_slb_default .slb_content .slb_next .slb_template_tag {
  opacity:0.8 !important;
}

/* POST NAVIGATION */
.post-navigation {
  display:grid;
  grid-template-columns:20px auto 15% 20px;
  align-items:center;
  width:98%;
  grid-column:1 / span 2;
  column-gap:3%;
  opacity:1;
  margin:2vh 0 5vh 0;
  @media (max-width:1200px) {
      grid-template-columns:20px auto 20% 20px;
  }
  @media (max-width:767px) {
      grid-template-columns:20px auto 20px;
      margin-bottom:60px;
  }
  img {
      transition:0.3s ease all;
      &:hover {
          opacity:0.5;
          cursor:pointer;
      }
  }
  hr {
      width:100%;
      border-top:1px solid #707070;
      @media (max-width:767px) {
          display:none;
      }
  }
  span {
      text-align:center;
  }
  .right-arrow {
      transform:rotate(180deg);
  }
}

/* PAGE NOT FOUND */

.pagenotfound {
  margin-bottom:125px;
  form {
    display:grid;
    grid-template-columns:1fr 10%;
    column-gap:30px;
    .search-input {
      font-size: 1.2rem;
      color: $charcoal;
      font-weight: 300;
      border-radius: 0;
      padding-bottom: 5px;
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: #c8caca;
    }
    button {
      font-size: 1.5rem;
      background: none;
      border: 0;
      &:hover {
        color:$darkblue;
      }
    }
    .search-input,
    .search-input:focus {
      border: none;
      border-bottom: 1px solid #000;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
    }
    .fa-search {
      position:relative;
      top:auto;
      right:auto;
      color:#575348;
    }
  }
}
