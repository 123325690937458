/* Articles */

.articles-grid {
    display:grid;
    grid-template-columns:20% auto;
    padding: 2% 7vw;
    @media (max-width:1200px) {
        padding:2% 3vw;
    }
    @media (max-width:767px) {
        display:block;
    }
    .page-header {
        grid-area:1 / 2 / 1 / 2;
        margin-bottom:30px;
        &:after {
            width:35vw;
        }
        @media (max-width:767px) {
            grid-area: 1 / 1 / 1 / 1;
        }
        .page-title {
            justify-content:left;
            padding:5em 15% 5em 5%;
            @media (max-width:767px) {
                padding:3em 5%;
            }
        }
        .category-title {
            h1 {
                font-size: calc(18px + 2vw);
            }
        }
    }
}

.articles {
    width: 100%;
    max-width:1400px;
    margin:0 auto 20px auto;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    grid-area: 2 / 2 / 2 / 2;
    column-gap:5%;
    @media (max-width:767px) {
        display:block;
        padding:0 3%;
        grid-area:2 / 1 / 2 / 1;
        margin: 0 auto 40px auto;
    }
    .type-post {
        position:relative;
        width: calc(50% - 3%);
        margin:0 0 10rem 0;
        box-sizing: border-box;
        height:fit-content;
        min-height:300px;
        &:nth-child(2n) {
            margin-top:10rem;
            @media (max-width:767px) {
                margin-top:0;
            }
        }
        @media (max-width:767px) {
            margin-bottom:10rem;
            width:100%;
        }
        a {
            &:hover {
                text-decoration:none;
            }
        }
        h3 {
            position:relative;
            margin-bottom:5px;
            &.no-image {
                margin-top:50px;
                &:before {
                    position:absolute;
                    content:"";
                    height:1px;
                    width:80%;
                    background:#707070;
                    top:-50px;
                    left:0;
                    @media (max-width:767px) {
                        width:100%;
                    }
                }
            }
        }
        img {
            max-width:90%;
            height:auto;
            margin-bottom:50px;
        }
        .post-date {
            font-family:$montserrat;
            font-size:0.9rem;
            color:$charcoal;
            font-weight:700;
            margin-bottom:20px;
        }
    }
}

.sidebar{
    margin-top:5em;
    // MOBILE NAV SECTION
    .left-nav {
        display: none;
    }

    .top-nav{
        display: block;
        padding-left: 5vw;
        padding-top: 5vw;
        padding: 5vw;
        font-size: 16px;
        font-family: $montserrat;
        font-weight: 300;

        // MOBILE PORTFOLIO TITLE
        .top-of-page{
            // padding-left: 5vw;
            padding-top: 5vw;

            h1{
                color: #0B284C;
                font-size: 30px;
                font-weight: 300;
            }
        }

        .anUnderline{
            width: 60%;  //?? need to think on these units
            float: right;
            text-align: right;
            padding-bottom:5px;
            border-bottom: 1px solid black;

            .down-arrow{
                transform: rotate(90deg);
            }
        }

        .drop-nav{
            display: none;
            position: absolute;
            background-color: white;
            left: 40vw;
            z-index: 5;
            width:55%;
            background:$slate;
            li{
                padding: 0 8vw;
                line-height: 40px;  
                font-weight: 300;
                &:hover {
                    background-color: $lightblue;
                    color:#0B284C;
                }
                a {
                    color: #575348;
                    display: block;
                    text-decoration: none;
                }
            }
        }
    }

    // DESKTOP NAV SECTION
    @media(min-width: 767px){
        .top-nav {
            display: none;
        }

        .left-nav {
            display: block;
            margin-left:5%;
            li{
                display:block;
                font-family: $montserrat;
                font-weight: 300;
                a {
                    color: #575348;
                    display: block;
                    text-decoration: none;
                    font-size:1.1em;
                    line-height:2.4em;
                    transition:0.3s ease all;
                    position:relative;
                }
            }
            li a:hover, li.current-cat a {
                padding-left:20px;
                &:before {
                    content: '__________________ ';
                    padding-right: 25px;
                    width: 100%;
                    height: auto;
                    left:-160px;
                    top:-6px;
                    color:#0B284C;  
                    position:absolute; 
                }
            }
        }
    }
}

/* Post Content  */

article.post {
    .page-header {
        margin-bottom:100px;
    }
    p {
        &:first-child {
            font-size:1.5rem;
            line-height:2.4rem;
            @media(max-width:767px) {
                font-size:1.4rem;
                line-height:2.2rem;
            }
        }
    }
}

.post-content {
    em {
        font-style:italic;
    }
}

.post-social {
    font-family:$montserrat;
    color:$charcoal;
    margin:0 auto 100px auto;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    strong {
        margin:0 15px 0 0;
    }
    .fa {
        background:$lightblue;
        border-radius:50%;
        width:50px;
        height:50px;
        display:grid;
        align-items:center;
        justify-items:center;
        color:#fff;
        font-size:1.2rem;
        transition:0.3s ease all;
        margin:0 5px;
        cursor:pointer;
        &:hover {
            background:#94A9BC;
        }
    }
    a {
        text-decoration:none;
    }
}

.post-related {
    background:$slate;
    padding:4rem;
    @media(max-width:1200px) {
        padding:3rem;
    }
    a {
        text-decoration:none;
    }
    h3 {
        grid-area:1 / 1 / 1 / span 3;
        margin-bottom:40px;
    }

    .post-group {
        display:grid;
        grid-template-columns:repeat(3,1fr);
        grid-gap:30px;
        @media (max-width:767px) {
            grid-template-columns:1fr;
        }
        .related {
            display:grid;
            grid-template-rows:1fr auto;
        }
    }
}

