.builder__grid {
    padding: 0 8%;
    article {
        color: #575348;
        margin-top:30px;
        margin-bottom:40px;
        p {
            font-weight: 300;
            margin-bottom:30px;
        }
    }
    @media(min-width:767px) {
        article {
            padding: 0;
            color: #575348;
            p {
                font-weight: 300;
            }
        }
    }
}

/* CONTENT/IMAGE BLOCK */
.builder__contentimage {
    display:grid;
    margin-bottom:70px;
    article {
        margin-bottom:0;
    }
    @media (min-width:1023px) {
        grid-template-columns: 1fr 1fr;
        align-items:center;
        column-gap: 80px;
        margin-bottom:120px;
    }
} 

@media (min-width:767px) {
    .builder__grid {
        .right {
            img {
                order:2;
            }
            article {
                order:1;
            }
        }
    }
}

/* HIGHLIGHT BLOCK */
.builder__centered {
    display: grid;
    padding: 40px 0 0;
    margin: 0 auto;
    h2{
        font-weight: 300;
    }
    p{
        font-size: calc(12px + 1vw);
        color: #575348;
        padding: 5px 5px 100px;
        max-width: 1000px;
        margin: 0 auto;
        font-weight: 300;
    }

    @media (min-width:767px) {
        grid-template-columns: 1fr;
        column-gap: 80px;
        padding: 80px 0 0;
        h2 {       
            text-align: center;
            margin-bottom:40px;
        }
        p {
            padding: 0 30px 130px;
            line-height:2.5rem;
        }
    }     
}

/* CTA BLOCK */
.builder__contentcta {
    display: grid;
    padding-bottom: 40px;
    max-width: 1000px;
    margin: 0 auto;
    padding:0 8%;
    margin-bottom:70px;
    p {
        padding-bottom: 1%;
        font-weight:300;
    }
    @media (min-width:767px) {
        justify-items:center;
        text-align:center;
        margin-bottom:120px;
        p {
            padding-bottom: 3%;
        }
    }
}

/* IMAGE GALLERY BLOCK */ 
.builder__images {
    display:grid;
    margin-bottom:70px;
    column-gap:60px;
    img {
        width:100%;
        margin:0 auto;
        padding-bottom:40px;
    }
    @media(min-width:767px){
        grid-template-columns: 1fr 2fr;
        margin-bottom:120px;
    }
}

/* CENTERED LIST BLOCK */ 
.builder__list {
    text-align:center;
    margin-bottom:70px;
    .builder__column-list {
        line-height:2rem;
        @media (max-width:767px) {
            column-count:1 !important;
        }
    }
    @media (min-width:767px) {
        margin-bottom:120px;
    }
}

/* JOB OPPORTUNITIES BLOCK */ 
.builder__opportunities {
    background-color: #E1E9F0;
    padding:50px 8% 0px 8%;
    margin-bottom:70px;
    h2 {
        margin-bottom:30px;
    }
    .builder__job-container {
        display:grid;
        padding-bottom:30px;
        column-gap:50px;
        @media (min-width:767px) {
            grid-template-columns:repeat(3,1fr);
        }
        .builder__job {
            margin-bottom:40px;
            h5 {
                margin-bottom:20px;
            }
        }
    }
    @media (min-width:767px) {
        padding:60px 8% 10px 8%;
        margin-bottom:120px;
    }
}

/* CONTACT BLOCK */ 

.builder__form-block {
    display: grid;
    margin:70px 0 0;
    .builder__form{
        padding:0 16% 70px 8%;
    }
    @media (min-width:1023px) {
    grid-template-columns: 2fr 1fr;
    font-weight: 300;
    line-height: 30px;
    padding-bottom: 30px;
        .builder__form{
            padding:0 15% 0 10%;
        }
    }
}

/* VIDEO BLOCK */

.builder__video-block {
    margin:0 auto;
    text-align:center;
    margin-bottom:120px;
}