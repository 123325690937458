// MOBILE ONLY
@media (max-width:767px){
    .page-header{
        .page-title {
            padding: 40px 30px 20px;
        }
    }
}

.grid-policy{
    .paragraphs{
        max-width: 935px;
        margin: auto;
        padding-bottom: 100px;
        padding-left: 30px;
        padding-right: 30px;
        .terms{
            padding-top: 100px;
        }

        .large-paragraph{
            font-size: 16px;
            line-height: 35px;
            padding-bottom: 5%;
        }

        .info-pararaph{
            font-size: 14px; 
        }
        // DESKTOP ONLY
        @media (min-width:767px) {
            padding-bottom: 100px;
            .large-paragraph{
                font-size: calc(12px + 1vw);
                line-height: 45px;
                padding-top: 5%;
                padding-bottom: 5%;
            }
            .info-pararaph{
                font-size: calc(10px + .5vw);
            }
        }
    }
    .menu {
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        margin-top:30px;
        li {
            margin-bottom:15px;
            a {
                color:$darkblue;
                &:before {
                    content:"> ";
                }
            }
        }
    }
}