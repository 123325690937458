.portfolio-grid{
    display: block;
    @media(min-width: 767px){
        display: grid;
        grid-template-columns: 25% 75%;
        padding: 90px 7vw 20px 7vw;
    }

    .portfolio-nav{
        // MOBILE NAV SECTION
        .left-nav {
            display: none;
        }

        .top-nav{
            display: block;
            padding:5vw 3.5vw;
            font-size: 16px;
            font-family: $montserrat;
            font-weight: 300;

            // MOBILE PORTFOLIO TITLE
            .top-of-page{
                // padding-left: 5vw;
                padding-top: 5vw;
                h1{
                    color: #0B284C;
                    font-size: 30px;
                    font-weight: 300;
                }
            }

            .anUnderline{
                width: 60%;  //?? need to think on these units
                float: right;
                text-align: right;
                border-bottom: 1px solid black;
                padding-bottom:5px;
                .down-arrow{
                    transform: rotate(90deg);
                }
            }

            .drop-nav{
                display: none;
                position: absolute;
                background:$slate;
                left: 40vw;
                z-index: 5;
                width:55%;
                li{
                    padding: 0 8vw;
                    line-height: 40px;
                    font-weight: 300;
                    &:hover {
                        background-color: $lightblue;
                        color:#0B284C;
                    }
                    a {
                        color: #575348;
                        display: block;
                        text-decoration: none;
                        // font-family: $montserrat;
                        // font-weight: 300;
                    }
                }
            }
        }

        // DESKTOP NAV SECTION
        @media(min-width: 767px){
            .top-nav {
                display: none;
            }

            .left-nav {
                display: block;
                margin-left:5%;
                li{
                    display:block;
                    font-family: $montserrat;
                    font-weight: 300;
                    a {
                        color: #575348;
                        display: block;
                        text-decoration: none;
                        font-size:1.1em;
                        line-height:2.4em;
                        transition:0.3s ease all;
                        position:relative;
                    }
                }
                li a:hover, li.current-cat a {
                    padding-left:20px;
                    &:before {
                        content: '__________________ ';
                        padding-right: 25px;
                        width: 100%;
                        height: auto;
                        left:-160px;
                        top:-6px;
                        color:#0B284C;  
                        position:absolute; 
                    }
                }
            }
        }
    }

    .view-projects{
        .top-of-page{
            display: none;
            // DESKTOP PORTFOLIO TITLE
            @media(min-width:767px){
                display: block;
                padding: 0;
                h1{
                    color: #0B284C;
                    font-size: 60px;
                    font-weight: 300;
                }
            }
        }
    }
}

.projects-grid {
    display:block;
    margin:5vh 0;
    .projects-list {
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        margin-bottom:20px;
        clear:both;
        .group-img {
            padding-bottom:0;
            position:relative;
            width: 47%;
            margin:0;
            box-sizing: border-box;

            margin-bottom:40px;
            img {
                height:100%;
                min-height:250px;
                width:100%;
                object-fit:cover;
            }
            .overlay-text {
                display:none;
                ul {
                    margin-bottom:10px;
                    padding:0 30px;
                    li{
                        font-size:0.9rem;
                        letter-spacing: 0.51px;
                        color: #8AD2D0;
                        text-transform: uppercase;
                        font-weight:700;
                        display:inline-block;
                        margin-right:5px;
                        &:after {
                            content:"+";
                            margin-left:5px;
                        }
                        &:last-child:after {
                            content: "";
                        }
                    }
                }
                h3{
                    font-size:calc(15px + 1vw);
                    color: #FFFFFF;
                    padding:0 30px;
                    font-weight:500;
                    margin-bottom:30px;
                }
            }
            &:hover {
                .overlay-text {
                    height:100%;
                    width:100%;
                    display:flex;
                    flex-direction:column;
                    justify-content:flex-end;
                    -webkit-justify-content: flex-end;
                    position:absolute;
                    bottom:0;
                    left:0;
                    background: linear-gradient( 180deg, rgb(0, 0, 0, 0), rgb(0, 0, 0, .9));
                }
            }
        }
    }
}



@media (max-width:1200px) {
    .projects-grid {
        .projects-list {
            gap:2% 5%;
            margin-bottom:0;
           .group-img {
                width: 100%;
           }
        }
    }
}

.project-map {
    .top-of-page {
        width:65%;
        @media (max-width:767px) {
            width:100%;
        }
    }
    .acf-map {
        width: 100%;
        height: 700px;
        border: #ccc solid 1px;
        margin: 20px 0 10vh 0px;
        .gm-style-iw-c {
            padding:20px 15px;
        }
        .gm-style-iw-d {
            padding-bottom:5px;
            padding-right:15px;
        }
        h4 {
            margin-bottom:0;
        }
        .address {
            span {
                color:#0B284C;
                &:after {
                    content:"+";
                    margin:0 5px;
                }
                &:last-child:after {
                    content: "";
                }
            }
        }
    }
    
    /* fixes potential theme css conflict */
    .acf-map img {
       max-width: inherit !important;
       width:50px;
    }
}