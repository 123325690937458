.container-home {
    padding: 0 8% 100px;
    @media (max-width:767px) {
        padding-bottom:50px;
    }
}
.container-search {
    padding: 300px 8%;
    text-align: center;
}
.home-hero {
    height: 85vh;
    width: 100%;
    position: relative;
    @media (max-width:767px) {
        height:75vh;
    }
    &:after {
        background: linear-gradient(180deg,transparent,rgba(17,17,14,.41));
        bottom: 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    video {
        height:100%;
        width:100%;
        object-fit:cover;
        z-index:2;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit:cover;
        z-index:1;
        /* This moves the image to the right for the two columned image to appear better on mobile. */
        @media (max-width:767px) {
            object-position:30% 10%;
        }
    }
    h1 {
        position: absolute;
        bottom: 8%;
        padding: 0 30% 0 8%;
        font-size: calc(26px + 3vw);
        color: #ffffff;
        font-weight:500;
        line-height:1;
        z-index:2;
        @media (max-width:1200px) {
            padding:0 8%;
        }
    }
}

.home-portfolio {
    display: grid;
    gap: 5vw;
    padding: 5vw 0 8vw 0;
    justify-content: center;
    img {
        width:100%;
        object-fit:contain;
        &:nth-child(2n) {
            width:90%;
            @media (max-width:767px) {
                width:100%;
            }
        }
        &:nth-child(3n) {
            width:85%;
            @media (max-width:767px) {
                width:100%;
            }
        }
    }
    .group-img {
        position:relative;
        height:100%;
        img {
            height:auto;
            object-fit:cover;
        }
        .overlay-text {
            display:flex;
            flex-direction:column;
            justify-content:flex-end;
            -webkit-justify-content:flex-end;
            position:absolute;
            bottom:0;
            left:0;
            height:100%;
            width:100%;
            background: linear-gradient( 180deg, rgb(0, 0, 0, 0), rgb(0, 0, 0, .8));
            ul {
                margin-bottom:10px;
                padding:0 5%;
                li{
                    font-size:0.9rem;
                    letter-spacing: 0.51px;
                    color: #8AD2D0;
                    text-transform: uppercase;
                    font-weight:700;
                    display:inline-block;
                    margin-right:5px;
                    &:after {
                        content:"+";
                        margin-left:5px;
                    }
                    &:last-child:after {
                        content: "";
                    }
                }
            }
            h3{
                font-size:calc(15px + 1.5vw);
                color: #FFFFFF;
                padding:0 5%;
                font-weight:500;
                margin-bottom:40px;
                @media(max-width:767px) {
                    font-size:calc(16px + 2vw);  
                    margin-bottom:20px;
                }
            }
        }
    }
    .home-content {
        h2 {
            font-size: 1.4em;
            line-height:2.5rem; 
            padding: 0;
            font-weight: 300;
        }
        hr {
            width: 100%;
        }
        .inline {
            display: grid;
            padding: 2vw 0;
            grid-template-columns: 15% 1fr;
            justify-content: center;
            column-gap:2vw;
            a {
                color:#575348;
            }
        }
    }
    @media (min-width:767px) {
        grid-template-columns: repeat(2, 1fr);
        .home-content {
            align-self:center;
            h2 {
                font-size: calc(5.2px + 1.5vw);
             
            }
        }
    }
}

.right {
    @media (min-width:767px) {
        grid-template-columns:50% 50%;
    }
}

.home {
    .post-related {
        margin:0 8%;
        display:grid;
        grid-template-columns:40% 1fr;
        column-gap:50px;
        @media(max-width:767px) {
            display:block;
        }
        .post-group {
            display:block;
            @media(max-width:767px) {
                margin-bottom:30px;
            }
        }
        .post-list {
            ul {
                li {
                    margin-bottom:15px;
                    a {
                        color:#575348;
                    }
                }
            }
        }
    }
}