/* Footer */

footer {
    background: #0B284C;
    color: #fff;
    float: left;
    width:100%;
    margin: 0 auto;
    padding:50px 0;
    p {
    font-size:0.8rem;
    line-height:1.5rem;
    }
}


.address {
    text-align: center;
    grid-area: 1 / 2 / 2 / 3;
    color:#B7CBDD;
    font-size: 14px;
    text-decoration: none;
    line-height:1.6em;
    font-weight:300; 
    margin-bottom:20px;
    a[href^="tel:"] {
        color:#B7CBDD;
    }   
}

 .links {
    text-align: center;
    a {
        opacity:1;
        transition:0.3s ease all;
        img {
            width: auto;
            height: 20px;
            display:inline-block;
            margin-bottom:20px;
            padding:0 5px;
            @media (min-width:1200px) {
                display:block;
            }
        }
        &:hover {
            opacity:0.8;
        }
    }
    @media (min-width:1200px) {
        padding-top:5px;
    }
}

.bennett{
    p:not(.logo) {
        text-align: center;
        font-size: 10px;
        font-weight: normal;
        padding-top: 10px;
        color: $white;
        line-height:1rem;
    }
}

.logo{
    display: flex;
    justify-content: center;

    img {
        width: 187px;
    }
}

.footer-nav {
    text-align: center;
    .items {
        ul {
            list-style: none;
            padding: 0;
            column-count: 2;
            display:inline-block;
            margin-left: 0px;
            @media (min-width:768px) {
                column-count: 3;
            }
            a {
                display: flex;
                justify-content:center; 
                line-height: 2.5em;
                font-size: 14px;
                padding:0 20px;
                text-decoration: none;
                color: #F8F8F8;
                font-weight:300;
                &:hover {
                    color:$slate;
                }
                @media (min-width:768px) {
                    justify-content:left;
                }
            }
        }
    }
}


/* DESKTOP FOOTER */

@media (min-width:1200px) {

    .container{
        display: grid;
        overflow-x: auto;
    }
    
    .footer-grid {
        display: grid;
        grid-template-columns: 1.5fr 1.25fr 0.5fr auto;
        grid-template-rows: 1fr;
        column-gap:5%;

    }

    .logo img{
        width:350px;
        margin:30px 0;
        margin-left: 50px;
    }
        
    .items .child { 
        display: none;
    }  
    
    .address {
        grid-area: 1 / 2 / 2 / 3;
        text-align: left;
        p{
            color:#B7CBDD;
        }
        a{
            color:#B7CBDD;
            text-decoration: none;
        }
    }
} 
   


/* DEKSTOP HEADER */

@media (min-width:767px) {

    .post-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 40px 30px;
    }

    .logo {
        justify-self:start;
        align-self:end;
        grid-area: 1 / 1 / 3 / 2;
        img {
            margin:0;
        }
    }

    nav {
        grid-area: 1 / 1 / 2 / 4;
        justify-self:end;
        padding:0;
        margin-bottom:0;
        ul {
            padding:0 15px;
            margin:15px auto;
        }
        li {
            margin:0 10px;
        }
        a {
            font-size:0.9rem;
        }
    }

    .address-area {
        grid-area: 2 / 2 / 3 / 4; 
        display:grid;
        grid-template-columns:1fr 30%;
        justify-items:end;
    }
}