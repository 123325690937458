.portfolio-banner{
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width:100%;
      height:100%;
      background: linear-gradient( 180deg, rgba(0, 0, 0, 0), rgba(17, 17, 14, 0.61));
    }
    h1{
        z-index: 2;
    }
    img {
        object-fit:cover;
        object-position:center center;
    }

    // DESKTOP
    @media(min-width: 767px){
        min-width: 100%;
    }
}

.bar-container{
    display: grid;
    grid-template-columns: auto;
    background-color:$slate;
    width: 100vw;
    margin: 0;
    .bar-item{
        padding: 30px 5% 20px 5%;
        &.category {
            span {
                &:after {
                    content:", "
                }
                &:last-child {
                    &:after {
                        content:"";
                    }
                }
            }
        }
        h5{
            text-align: left;
            font: normal normal 600 16px/19px Montserrat;
            color: #0B284C;
            text-transform: uppercase;
        }
        p{
            text-align: left;
            font: normal normal normal 15px/22px Montserrat;
            color: #575348;
        }
    }

    // DESKTOP
    @media(min-width: 767px){
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        padding:0 3vw;
    }
}

.portfolio-navigation {
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    column-gap:2px;
    margin-top:2px;
    .previous,.next {
        background:$gray;
        width:100%;
        text-align:center;
        padding:20px 0;
        transition:0.3s all ease;
        &:hover {
            background:$lightblue;
        }
    }
    a {
        color:$charcoal;
        &:hover {
            text-decoration:none;
        }
    }
}

.bottom-nav {
    display:grid;
    grid-template-columns:40% 20% 40%;
    margin-bottom:100px;
    align-items:center;
    @media (max-width:767px) {
        grid-template-columns:25% 50% 25%;
    }
    a.button {
        justify-self:center;
    }
    a {
        color:$charcoal;
    }
    .previous,.next {
        img {
            margin:0 20px;
        }
    }
}

.previous {
    text-align:right;
    img {
        transform: rotate(180deg);
    }
}

.about-project{
    padding: 8% 0;
    // DESKTOP
    @media(min-width: 767px){
        padding: 8% 0% 3%;

    }
}

.video-container {
    padding:0 10%;
    margin-bottom:80px;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 

.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}