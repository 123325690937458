/* People Widget */
.people-widget{
    display: grid;
    grid-template-columns: 20% auto;
    column-gap: 10%;
    background-color: #E1E9F0;
    padding: 6% 8%;
    color: #575348;
    .people-title{
        display: flex;
        justify-content:center;
        flex-direction:column;
    }
    .people-images {
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        column-gap:40px;
        img {
            height:auto;
            width:100%;
            min-height: calc(220px + 10vw);
            max-height: calc(300px + 5vh);
            object-fit: cover;
        } 
    }
}

@media (max-width:767px) {
    .people-widget {
        display:block;
        padding: 10% 8%;
        .people-title {
            margin-bottom:40px;
        }
        .people-images {
            grid-template-columns:repeat(2, 1fr);
            column-gap:30px;
            img {
                &:nth-child(3n) {
                    display:none;
                }
            }           
        }
    }
}

/* JOBS WIDGET */

.jobs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10%;
    background-color: #E1E9F0;
    padding: 6% 8%;
    color: #575348;
    h4{
        font-size: calc(16px + 2vw);
        font-weight: 300;
        color: #0B284C;
        line-height:1.4;
    }
  
    .work-for-us{
        display: block;
    }
  
    .employee-req{
        font-size: calc(16px + .5vw);
        font-weight: 300;
        line-height:1.4em;
        @media (max-width:767px) {
          font-size:calc(16px + 1vw);
        }
    }
  
    .positions{
        color: #575348;
        padding-top: 90px;
        .position-title{
            font-size: 20px;
            font-weight: bold;
        }
  
        .position-desc{
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
            padding-bottom: 15px;
        }
    }
  }
  
  @media (max-width:767px) {
    .jobs {
      display:block;
      padding: 10% 8%;
      .positions {
        padding-top:30px;
      }
    }
  }
  
  /* ARTICLE/PORTFOLIO WIDGETS CODE */
  
  .post-content {
    max-width:900px;
    margin:0 auto 80px auto;
  }
  
  .post-gallery {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-items:left; 
      margin-bottom:80px;
      padding:0 10%;
      li {
        width:46%;
        margin:2% 2%;
        @media (max-width:767px) {
          width:100%;
          margin:2% 0;
        }
        &:nth-child(3) {
          @media (max-width:767px) {
              display:none;
          } 
        }
        img {
          object-fit:cover;
          width:100%;
          max-height:60vh;
        }
      }
    }
    .project-excerpt {
        padding:1.5rem 3rem;
        max-width:48%;
        max-height:60vh;
        @media (max-width:1200px) {
            max-width:45%;
            padding: 1.5rem 2rem;
        }
        @media(max-width:767px) {
            max-width:100%;
            padding:1.5rem 0;
        }
        p {
            font-size:1.5rem;
            line-height:2.6rem;
            @media (max-width:1200px) {
                font-size:1.2rem;
                line-height:2rem;
            }
        } 
        .project-button {
            font-size:1.1rem;
            position:relative;
            color:$charcoal;
            padding-left:70px;
            span {
                background:#fff;padding-left:20px;
            }
            &:after {
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0.5em;
                border-top: 1px solid $lightblue;
                z-index: -1;
            }
        }
    }
  }
  
  .post-image {
    margin:0 auto 80px auto;
  }