.search-page {
    display: block;
    @media(min-width: 767px){
        display: grid;
        grid-template-columns: auto;
        padding: 90px 7vw 20px 7vw;
    }
    .container {
        margin-bottom:100px;
        h3 {
            a {
                text-decoration:none;
                &:hover {
                    text-decoration:underline;
                }
            }
        }
        hr {margin-bottom:30px;}
    }
}
