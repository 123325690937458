.people-grid{
    display: flex;
    flex-wrap: wrap;
    padding: 0 8% 0% 8%;
    .employees{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        p{
            display: block;
            line-height:normal;
        }
        img {
            width:100%;
            height:auto;
            object-fit:cover;
            min-height:375px;
            height:325px;
        }
        h3{
            font-weight: 300;
            padding-top: 30px;
            padding-bottom: 10px;
            margin-bottom:0;
            line-height:1.8rem;
        }

        .title{
            font-size: 12px;
            line-height:1.5rem;
            font-weight: bold;
            color: #575348;
            padding-bottom: 30px;
            text-transform:uppercase;
        }
    }
    .people-paragraph{
        display: block;
        margin: 40px 0 100px 0;
        p {
            font-size: calc(12px + 1vw);
            line-height: 1.5;
            color: #575348;
            font-weight: 300;
        }
    }
}

// DESKTOP PEOPLE GRID
@media(min-width: 767px){
    .people-grid {
        display: grid;
        padding: 0 8% 0% 8%;
        .employees{
            grid-template-columns: repeat(3, 1fr);
            column-gap: 30px;
            img {
                height:40vh;
            }
            .title{
                font-size: calc(12px + .4vw);
                font-weight: bold;
                color: #575348;
                padding-bottom: 42px;
            }
        }
        .people-paragraph {
            margin: 40px 15vw 100px 15vw;
        }
    }
    
}

@media (min-width:1200px) {
    .people-grid {
        .employees {
            img {
                height:55vh;
            }
        }
    }
}

@media (min-width:1600px) {
    .people-grid {
        .employees {
            column-gap:70px;
        }
    }
}

