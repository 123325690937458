.gform_wrapper.gravity-theme .gfield textarea {
    border: 1px solid black;
    border-radius: 2px;
}

.gform_wrapper.gravity-theme .gfield textarea.large {
    height: 462px !important;
    padding-top: 30px;
    padding-left: 45px;
}


.gform_wrapper.gravity-theme .gform_fields {
    grid-row-gap: 80px !important;
    grid-column-gap: 10% !important;
}

.gfield_label {
    display: none !important;
}

.gform_wrapper.gravity-theme .gfield input {
    border-bottom: 1px solid black;
}

.gform_wrapper.gravity-theme .ginput_complex label {
    display: none !important;
}

.gform_wrapper.gravity-theme .gform_footer input {
    border: 1px solid #565348;
    text-align: center;
    width: 182px;
    height: 45px;
    transition:0.2s ease all;
    cursor:pointer;
    &:hover {
        background:#565348;
        color:#fff;
    }
}

// MOBILE FORM SECTION
@media(max-width:766px) {
    .gform_wrapper.gravity-theme .gfield textarea {
        font-size: 16px !important;
        padding:8%;
    }

    .gform_wrapper.gravity-theme .gfield textarea.large {
        height: 462px !important;
        padding-top: 30px;
        padding-left: 25px;
    }

    .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=tel]{
        font-size: 16px !important;
    }

    // .gform_wrapper.gravity-theme .gform_footer.top_label
    //     margin: auto;
    //     padding: 16px 0;
    //     // margin-left: 65px !important;
    // 
}